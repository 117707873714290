import RangedReportRoute from '../routes/ranged-report-route'
import { getOwner } from "@ember/application";

export default RangedReportRoute.extend({

    insightsAdapter : null,

    beforeModel(){
        this._super(...arguments);
        this.controllerFor('application').set('activeReport', this.get('routeMeta.users.name'));
        this.controllerFor('loading').set('activeReport', this.get('routeMeta.users.name'));
        let applicationInstance = getOwner(this);
        this.set('insightsAdapter', applicationInstance.lookup('adapter:insights'));
    },

    model(params){

        this._super(...arguments);
        let self = this;
        let since = this.since;
        let until = this.until;

        let cachedReport = this.getCachedReport();
        if (cachedReport){
            return cachedReport;
        }

        let insightsAdapter = this.insightsAdapter;
        let reportObject = null;

        return insightsAdapter.getProductOwnershipReport(since, until).then(
           function(result){
               reportObject=result;
               let report = [
                reportObject.trial,
                reportObject.proSuiteV11ProSuite,
                reportObject.proSuiteV11ProSuiteUpgrade,
                reportObject.proSuiteV11AlbumSuite,
                reportObject.proSuiteV10,
                reportObject.proSuiteV10Upgrade,
                reportObject.proSuiteV10Lease,
                reportObject.proSuiteV10Monthly,
                reportObject.albumSuiteV10,
                reportObject.proSuite,
                reportObject.albumSuite,
                reportObject.liteSuite,
                reportObject.proEnhnacements,
                reportObject.proSuiteUpgrade,
                reportObject.albumSuiteUpgrade,
                reportObject.liteSuiteUpgrade,
                reportObject.totalSuiteUsers,
            ]
            let retVal = {reportObject:reportObject,  report:report};
            self.cacheReport(retVal);
            return retVal;
           }
       )
    }

});
